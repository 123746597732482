import React from "react";

import { SCREEN_ID } from "../../api/models/consts";
import { UnableMicIcon } from "../icons/UnableMicIcon";

export const ParticipantItem = ({participant, mainUser, changeMain, visible} : any) => {
    return <div onClick={() => changeMain(participant.id)} id={participant?.id} className={`participant${participant?.id === mainUser ? ' main' : ''}${participant?.id === SCREEN_ID ? ' participant_screen' : ''}${participant?.id !== SCREEN_ID && (!participant?.visible || !visible) ? ' participant_hidden' : ''}`}>
        <video autoPlay={true} playsInline={true} controls={false} id={`video-${participant?.id}`} />
        {participant?.id !== SCREEN_ID && <div className="participant__name">{participant?.name}</div>}
        {!participant?.isMicOn && <div className="participant__mic"><UnableMicIcon /></div>}
    </div>;
}