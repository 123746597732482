import { forwardRef, useMemo } from "react";
import Select, { components } from "react-select";

import "./CustomSelect.scss";

interface IProps {
    title?: string;
    description?: string;
    options: any[];
    value: any | null;
    onChange: (value: any | undefined | null) => void;

    onSearch?: (value: string) => void;
    loadOptions?: boolean;
    disabled?: boolean;
    fullOption?: boolean;
    error?: any;
    onFocus?: () => void;
    onBlur?: () => void;
    required?: boolean;
    isClearable?: boolean;
    isSearchable?: boolean;
    placeholder?: string;
    cyName: any;
    isPositionAbsolute?: boolean;
}

const Option = (
    props: any
) => {
    const index = props.options?.findIndex((x: any) => x.value === props.value);
    return (
        <components.Option {...props}>
            <span data-cy={`option-${index}`}>{props.data.label}</span>
        </components.Option>
    );
};

export const CustomSelect = forwardRef((props: IProps, ref: any) => {
    const selectValue = useMemo(() => {
        if (props.fullOption) {
            return props.value;
        }
        const index =
            props.options?.findIndex(o => o.value === props.value) ?? -1;
        const option = props.options?.[index];
        return option
            ? { value: index, label: option.label }
            : null;
    }, [props.options, props.value]);
    
    return (
        <div data-cy={props.cyName} className="form-select__container">
      {props.title && <span
          className={`form-select__title ${
              props.error ? "form-select__title_error" : ""
          }`}
      >
        {props.title} {props.required && <div className="form-select__required">*</div>}
      </span>}
            <Select
                ref={ref}
                options={props.options}
                placeholder={props.placeholder ?? ""}
                onChange={(selected: any) => {
                    props.onChange(props.fullOption ? selected : selected?.value);
                }}
                value={selectValue}
                onInputChange={props.onSearch}
                isSearchable={props.isSearchable !== undefined ? props.isSearchable : true}
                isClearable={props.isClearable !== undefined ? props.isClearable : true}
                styles={styledConfig(props.error)}
                isLoading={props.loadOptions}
                noOptionsMessage={({ inputValue }) =>
                    !inputValue ? "Нет значений" : "Нет совпадений"
                }
                components={{Option}}
                loadingMessage={loadingMessage}
                isDisabled={props?.disabled ? props.disabled : false}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                menuPosition={props.isPositionAbsolute ? 'absolute' : 'fixed'}
            />
            {props.description && (
                <span className={`form-select__description`}>{props.description}</span>
            )}
        </div>
    );
});

const loadingMessage = () => 'Загрузка…';

const styledConfig = (error?: boolean) => ({
    menuPortal: (provided: any) => ({
        ...provided,
        zIndex: 3,
    }),
    control: (provided: any) => ({
        ...provided,
        boxShadow: 'none',
        borderColor: error ? "var(--input-border-error)" : "var(--input-border)",
        ":hover": {
            borderColor: error
                ? "var(--input-border-error)"
                : "var(--input-border-hover)",
        },
        ":focus-within": {
            borderColor: error
                ? "var(--input-border-error)"
                : "var(--input-border-active)",
            boxShadow: error
                ? "0 0 0 1px var(--input-border-error)"
                : "0 0 0 1px var(--input-border-active)",
        },
        outline: 'none',
        borderRadius: '10px',
        maxWidth: '100%',
        minHeight: 40,
    }),
    valueContainer: (provided: any) => ({ ...provided, padding: '0.25rem' }),
    placeholder: (provided: any) => ({ ...provided, fontSize: '16px' }),
    noOptionsMessage: (provided: any) => ({ ...provided, fontSize: '16px' }),
    option: (provided: any, data: any) => ({ ...provided, fontSize: '16px', cursor: 'pointer', background: '#fff', 
        color: data?.selectProps?.value?.label === data?.label ? '#147dc1' : 'black',
        ":hover": {
            color: '#147dc1'
        }}),
    indicatorSeparator: () => ({}),
});