export function env() {
    return {
        //@ts-ignore
        REACT_APP_CALL_HUB_URL: window.communicationConfig && window.communicationConfig['REACT_APP_CALL_HUB_URL'] ? window.communicationConfig['REACT_APP_CALL_HUB_URL']
            : process.env.REACT_APP_CALL_HUB_URL,
        //@ts-ignore
        REACT_APP_CHAT_HUB_URL: window.communicationConfig && window.communicationConfig['REACT_APP_CHAT_HUB_URL'] ? window.communicationConfig['REACT_APP_CHAT_HUB_URL']
            : process.env.REACT_APP_CHAT_HUB_URL,
        //@ts-ignore
        REACT_APP_BACKEND_URL: window.communicationConfig && window.communicationConfig['REACT_APP_BACKEND_URL'] ? window.communicationConfig['REACT_APP_BACKEND_URL']
            : process.env.REACT_APP_BACKEND_URL,
        //@ts-ignore
        REACT_APP_REGISTER_URL: window.communicationConfig && window.communicationConfig['REACT_APP_REGISTER_URL'] ? window.communicationConfig['REACT_APP_REGISTER_URL']
            : process.env.REACT_APP_REGISTER_URL,
        //@ts-ignore
        REACT_APP_USE_TM_AUTH: (window.communicationConfig && window.communicationConfig['REACT_APP_USE_TM_AUTH'] ? window.communicationConfig['REACT_APP_USE_TM_AUTH']
            : process.env.REACT_APP_USE_TM_AUTH) === "true",
        //@ts-ignore
        REACT_APP_ROOT_FOLDER: window.communicationConfig && window.communicationConfig['REACT_APP_ROOT_FOLDER'] ? window.communicationConfig['REACT_APP_ROOT_FOLDER']
            : process.env.REACT_APP_ROOT_FOLDER,
        //@ts-ignore
        REACT_APP_WAIT_ADMIN_MESSAGE: window.communicationConfig && window.communicationConfig['REACT_APP_WAIT_ADMIN_MESSAGE'] ? window.communicationConfig['REACT_APP_WAIT_ADMIN_MESSAGE']
            : process.env.REACT_APP_WAIT_ADMIN_MESSAGE,
        //@ts-ignore
        REACT_APP_STUNS_LIST: window.communicationConfig && window.communicationConfig['REACT_APP_STUNS_LIST'] ? window.communicationConfig['REACT_APP_STUNS_LIST']
            : process.env.REACT_APP_STUNS_LIST,
        //@ts-ignore
        REACT_APP_ENABLE_WHITE_BOARD: (window.communicationConfig && window.communicationConfig['REACT_APP_ENABLE_WHITE_BOARD'] ? window.communicationConfig['REACT_APP_ENABLE_WHITE_BOARD']
            : process.env.REACT_APP_ENABLE_WHITE_BOARD) === "true",
        //@ts-ignore
        REACT_APP_SHOW_VIDEOCHAT_LINK: (window.communicationConfig && window.communicationConfig['REACT_APP_SHOW_VIDEOCHAT_LINK'] ? window.communicationConfig['REACT_APP_SHOW_VIDEOCHAT_LINK']
            : process.env.REACT_APP_SHOW_VIDEOCHAT_LINK) === "true",
        //@ts-ignore
        REACT_APP_SERVICE_TYPE: window.communicationConfig && window.communicationConfig['REACT_APP_SERVICE_TYPE'] ? window.communicationConfig['REACT_APP_SERVICE_TYPE']
            : process.env.REACT_APP_SERVICE_TYPE,
        //@ts-ignore
        REACT_APP_CHAT_FILE_TYPES: window.communicationConfig && window.communicationConfig['REACT_APP_CHAT_FILE_TYPES'] ? window.communicationConfig['REACT_APP_CHAT_FILE_TYPES']
            : process.env.REACT_APP_CHAT_FILE_TYPES
    };
}